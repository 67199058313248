import React from 'react';
import StoreButton from './StoreButton';

import AppleStoreLogo from 'assets/icons/apple-store-logo.svg';

const AppleStoreButton = ({ ...props }) => {
  const handleLink = () => {
    window.open('https://apps.apple.com/app/effecto/id1617568819', '_blank');
  };

  return (
    <StoreButton
      onClick={handleLink}
      downloadText="Download on the"
      storeTitle="Apple Store"
      Icon={AppleStoreLogo}
      {...props}
    />
  );
};

export default AppleStoreButton;
